<template>
  <!-- eslint-disable vue/multi-word-component-names -->
  <div class="home">
    <div class="title">
      <p>列 斯 丽 旗 下 平 台 系 统 入 口</p>
    </div>
    <div class="jian"></div>
    <div class="item">
      <div class="one" @click="toMieHuoQi('https://extinguisher.lslch.com')">
        <p>
          智 慧 云 消 防<br />
          管 理 系 统
        </p>
      </div>
      <div class="one" @click="toMieHuoQi('https://lsldsb.lslch.com')">
        <p>
          智 能 挡 鼠 板<br />
          管 理 系 统
        </p>
      </div>
      <!-- 'https://muyu.lslch.com' -->
      <div class="one" @click="toMieHuoQi('')">
        <p>浴 室 安 全 智 能 预 警 <br />管 理 系 统</p>
      </div>
    </div>
    <div class="item">
      <div class="one" @click="toMieHuoQi('http://175.178.203.246:85/#/home')">
        <p>
          输 油 泵 房 智 能 预 警 <br />
          灭 火 管 理 系 统
        </p>
      </div>
      <div class="one" @click="toMieHuoQi('http://175.178.203.246:84/#/home')">
        <p>
          加 热 炉 智 能 预 警<br />
          灭 火 管 理 系 统
        </p>
      </div>
      <div class="one" @click="toMieHuoQi('')">
        <p>
          抽 油 机 净 化 装 置<br />
          管 理 系 统
        </p>
      </div>
    </div>

    <div class="tishi" v-show="isShow">
      <div class="ti">该系统正在升级中，暂时禁止访问！</div>
      <div class="btn" @click="isShow = false">确认</div>
    </div>
    <div class="beian">
      <a rel="nofollow" href="https://beian.miit.gov.cn/" target="_blank"
        >粤ICP备2022068829号-1</a
      >
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isShow: false,
    };
  },

  methods: {
    toMieHuoQi(url) {
      // window.locati{on.href = url
      if (url) {
        window.open(url, "_blank");
      } else {
        this.isShow = true;
      }
    },
  },
};
</script>
<style lang="less" scope>
.home {
  width: 100%;
  height: 90vh;
  padding-top: 10vh;
  background: url(../assets/bgc.png) no-repeat;
  background-size: 100% 100%;
  font-weight: bolder;
  font-size: calc(100vw * 20 / 1920);
  .title {
    width: 35vw;
    height: 13vh;
    line-height: 13vh;
    text-align: center;
    background: url(../assets/3.png) no-repeat;
    background-size: 100% 100%;
    color: #fff;
    font-size: calc(100vw * 30 / 1920);
    margin: 0 auto;
  }

  .jian {
    height: calc(100vw * 60 / 1920);
    width: calc(100vw * 30 / 1920);
    background: url(../assets/1.png) no-repeat;
    background-size: 100% 100%;
    margin: 5vh auto;
  }

  .item {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10vh;
    .one {
      background: url(../assets/2.png) no-repeat;
      background-size: 100% 100%;
      width: 22vw;
      height: 10vh;
      padding-top: 5vh;
      color: #fff;
      font-size: calc(100vw * 25 / 1920);
      text-align: center;
      margin: 0 3vw;
    }
  }

  p {
    color: #fff;
    font-size: 3calc (100vw * 30 / 1920);
    font-family: "黑体";
    cursor: pointer;
    animation: slidein 2s infinite;
  }
  .beian {
    position: fixed;
    bottom: 0;
    left: 0;
    text-align: center;
    width: 100%;
    height: calc(100vw * 100 / 1920);
    line-height: calc(100vw * 100 / 1920);
    a {
      color: #fff;
    }
  }

  @keyframes slidein {
    0%,
    100% {
      color: #fff;
      text-shadow: 0 0 calc(100vw * 5 / 1920) #34bcf1,
        0 0 calc(100vw * 5 / 1920) #34bcf1;
    }
    50% {
      text-shadow: 0 0 calc(100vw * 80 / 1920) #34bcf1,
        0 0 calc(100vw * 80 / 1920) #34bcf1;
    }
  }
}

.tishi {
  position: fixed;
  top: 50%;
  font-size: calc(100vw * 20 / 1920);
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(243, 247, 23);
  background-color: rgba(235, 96, 131, 0.8);
  border-radius: calc(100vw * 15 / 1920);
  width: 40vw;
  height: 20vh;
  padding: 5vh 0;
  .ti {
    text-align: center;
    height: 15vh;
    line-height: 15vh;
    font-size: calc(100vw * 35 / 1920);
  }
  .btn {
    text-align: center;
    height: 5vh;
    line-height: 5vh;
    cursor: pointer;
    width: 4vw;
    margin-left: 18vw;
    background-color: rgba(243, 247, 23, 01);
    color: #000;
    border-radius: 15%;
  }
}
</style>
