var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_vm._m(0),_c('div',{staticClass:"jian"}),_c('div',{staticClass:"item"},[_c('div',{staticClass:"one",on:{"click":function($event){return _vm.toMieHuoQi('https://extinguisher.lslch.com')}}},[_vm._m(1)]),_c('div',{staticClass:"one",on:{"click":function($event){return _vm.toMieHuoQi('https://lsldsb.lslch.com')}}},[_vm._m(2)]),_c('div',{staticClass:"one",on:{"click":function($event){return _vm.toMieHuoQi('')}}},[_vm._m(3)])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"one",on:{"click":function($event){return _vm.toMieHuoQi('http://175.178.203.246:85/#/home')}}},[_vm._m(4)]),_c('div',{staticClass:"one",on:{"click":function($event){return _vm.toMieHuoQi('http://175.178.203.246:84/#/home')}}},[_vm._m(5)]),_c('div',{staticClass:"one",on:{"click":function($event){return _vm.toMieHuoQi('')}}},[_vm._m(6)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShow),expression:"isShow"}],staticClass:"tishi"},[_c('div',{staticClass:"ti"},[_vm._v("该系统正在升级中，暂时禁止访问！")]),_c('div',{staticClass:"btn",on:{"click":function($event){_vm.isShow = false}}},[_vm._v("确认")])]),_vm._m(7)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('p',[_vm._v("列 斯 丽 旗 下 平 台 系 统 入 口")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 智 慧 云 消 防"),_c('br'),_vm._v(" 管 理 系 统 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 智 能 挡 鼠 板"),_c('br'),_vm._v(" 管 理 系 统 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("浴 室 安 全 智 能 预 警 "),_c('br'),_vm._v("管 理 系 统")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 输 油 泵 房 智 能 预 警 "),_c('br'),_vm._v(" 灭 火 管 理 系 统 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 加 热 炉 智 能 预 警"),_c('br'),_vm._v(" 灭 火 管 理 系 统 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 抽 油 机 净 化 装 置"),_c('br'),_vm._v(" 管 理 系 统 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"beian"},[_c('a',{attrs:{"rel":"nofollow","href":"https://beian.miit.gov.cn/","target":"_blank"}},[_vm._v("粤ICP备2022068829号-1")])])
}]

export { render, staticRenderFns }